<template>
  <section>
    <land-img
      :height="wdHeight"
      :src="cover"
      flat
      max-width="100%"
      tile
    >
      <v-container
        style="max-width: 1200px; height: 90%;"
      >
        <v-row
          class="ma-0 fill-height"
          align="center"
        >
          <v-col
            cols="12"
            md="7"
            sm="12"
          >
            <div class="co-flex-col ml-4">
              <span class="text-h6 text-md-h4 white--text ">{{ header.title }}</span>
              <span class="co-text-sm white--text mt-4">{{ header.english }}</span>
              <div class="co-w32 co-h2 co-bg-white mt-4" />
              <div
                class="co-w32"
                :class="[smAndDown ? 'co-h24' : 'co-h40']"
              />
              <span
                class="white--text"
                :class="[
                  smAndDown ? 'co-text-sm' : 'co-text-md',
                  smAndDown ? 'mt-4' : 'mt-10',
                ]"
              >{{ header.subtitle }}</span>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="5"
            class="hidden-sm-and-down"
          />
        </v-row>
      </v-container>
      <div
        v-if="item.coverName || item.coverAuthor"
        class="co-flex-row co-justify-end co-w-full px-10"
        style="height: 10%;"
      >
        <span class="co-text-2xs white--text">
          {{ item.coverName }}
          <span
            v-if="item.coverAuthor"
            class="px-1"
          >by</span>
          {{ item.coverAuthor }}
        </span>
      </div>
    </land-img>
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'

  export default {
    name: 'SectionGuideRecommend',
    props: {},
    data () {
      return {
        divisible: true,
        cover: '',
        item: {},
        classify: {},
        header: {},
        button: {},
        points: {
          xs: 300,
          sm: 400,
          md: 500,
          lg: 500,
          xl: 500
        },
      }
    },
    computed: {
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      },
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        // const pts = this.classify.points || {}
        const ht = this.points[name] || 500
        return ht
      },
      btnWidth () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 120
        } else {
          return 140
        }
      },
    },
    created () {
      this.getData()
    },
    methods: {
      getData () {
        const me = this
        let params = null
        const executed = function (res) {
          if (res.status) {
            const item = params.items[0] || {}
            const cnt = item.content || {}
            me.item = item
            me.classify = cnt.classify || {}
            me.header = cnt.header || {}
            me.button = cnt.button || {}
            me.cover = item.cover || ''
          }
        }
        params = web.banner.getParams({
          type: web.comm.BannerTypes.HOME_RECOMMEND,
          currentPage: '1',
          pageSize: '1',
          executed
        })

        api.httpx.getItems(params)
      }
    }
  }
</script>
